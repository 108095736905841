/* Import Sass mixins, variables, Compass modules, etc. */
@import "_base";

/*
input[type="text"],
input[type="email"],
input[type="password"] {
   height: $base-font-size * 1.8;
}
*/

input[type="radio"],
input[type="checkbox"] {
   margin-right: .25em;
   background: none;
   @include box-shadow(none);
}

// Fix positioning of throbber after adding height to inputs. Original css found in systems
html.js input.form-autocomplete {
   background-position: 99% 5px;
}
html.js input.throbbing {
   background-position: 99% -15px;
}

.form-type-managed-file input.form-submit {
  margin-left: .5em;
}

select {
  width: $medium-width;
  @include appearance(none);
  border: 2px solid $border-color;
  @include border-radius($input-border-radius);
  height: $input-height;
  padding: 0 8px;
  line-height: 18px;
  font-size: 14px;
  &:focus {
    border: 2px solid $color-orange;
  }
}

/* Custom Uniform JS Styles */


// Custom State select box width
#webform-component-state,
#webform-component-offers-grad-state {
  div.selector.fixedWidth {
    width: 100px;
  }
  div.selector.fixedWidth span {
    width: 65px;
  }
}



@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {


  html.lt-ie9 div.selector {
    background-image: url("../images/sprite1x.png");
  }

} /* END RETINA MEDIA QUERY */


// Tabs

.block-quicktabs {
  margin: 24px 0;
}

// Lists

.ui-list-wrpr ul, ul.ui-list {
  @include clean-list;

}

.teaser-list {
  li {
    margin-bottom: 20px;
  }
}

.grid-list {
	@include clean-list;
	@include horizontal-list($list-margin: 0, $list-item-margin-right: 12px, $list-item-margin-bottom: 12px);
}


/* Media Element Icons */

.me-cannotplay {
  &:hover {
    a {
      background-position: 0 0;
    }
  }
  a {
    @include hide-text;
    display: block;
    background: url('../images/me-play1x.png') no-repeat scroll center center transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 58px;
    height: 42px;
    background-position: 0 -42px;
    margin: -21px 0 0 -29px;
  }
}



.mejs-overlay-button {
width: 58px;
height: 42px;
margin: -21px 0 0 -29px;
background-position: 0 -42px;
background-image: url('../images/me-play1x.png');

}
.mejs-overlay:hover .mejs-overlay-button {
  background-position: 0 0;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {

.mejs-overlay-button,
.me-cannotplay {
  background-image: url('../images/me-play2x.png');
  background-size: 58px 84px;
}

} /* END RETINA MEDIA QUERY */

.search-results.node-results {
  padding: 0;
}
